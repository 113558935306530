body{
	/* Rectangle 5: */
	display: block;
	font-family: "Poppins";
	height: 100%;
	color: #8285A8;
	// background-color: #F3F6FA;
	background-color: white;
	padding: 64px 0 0 0;
	position: relative;
	// background-image: linear-gradient(-123deg, rgba(110,130,216,0.97) 0%, #F1BCBE 100%);
}

.landing .text-link{
	text-align: center;
	display: block;
	font-size: 1.5em;
	color: #8285A8;
}
.landing{
	text-align: center;
}

.row{
	margin: 0;
}

.loaded h1{
	// // opacity: 1;
	// animation-delay: 1s;
	// animation-name: fade-in;
    -webkit-animation-duration: 10s;
    animation-duration: 10s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.fade-in{
-webkit-animation-name: fadeIn;
animation-name: fadeIn;
}

#word-cycle span{
	font-weight: 600;
}


h1{
	font-weight: 400;
	@include font-size(1.8em, 3em, 3em, 3em, 3em);
	// text-shadow: 4px 4px 17px rgba(150, 150, 150, 0.57);
	margin: 96px 0 16px 0;
	text-align: center;
	position: relative;
	z-index: 1;
	padding: 0 16px;
	// opacity: 0;
	@include transition(all, 1s);
   -webkit-animation-duration: 5s;
    animation-duration: 5s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;

}

@keyframes fade-in {
   0% {
    opacity: 0;
	// color: red;
}
    100% {
    opacity:1;
	// color: blue;
}
}



h3{
	font-weight: 600;
}


.bottom{
	position: absolute;
	bottom: 0;
	width: 100%;
	height: 50%;
	left: 0;
	z-index: 0;
	background-color: #F3F6FA;
}
.download-button{
	background-image: linear-gradient(-123deg, rgba(110,130,216,0.97) 0%, #F1BCBE 100%);
	padding: 0 32px;
	// width: 200px;
	height: 48px;
	color: white;
	font-weight: 600;
	display:inline;
	border-radius: 32px;
	position: relative;
	float: right;
	// font-size: 1rem;
	text-align: center;
	@include flexbox;
	@include align-items(center);
	@include justify-content(center);
	-webkit-box-shadow: 10px 10px 30px 10px rgba(0,0,0,0.08);
    -moz-box-shadow: 10px 10px 30px 10px rgba(0,0,0,0.08);
    box-shadow: 10px 10px 30px 10px rgba(0,0,0,0.08);
    @include transition(all 300ms cubic-bezier(0.68, -0.1, 0.265, 1.55));
    &:hover{
    	@include transform(translateX(8px));
    	text-decoration: none;
    	color: white;
    }
}



.download-button .text span{
	opacity: 0.8;
	font-weight: 400;
}
header .col{
	padding: 0;
	margin: 0;
	height: 64px;
	&.right{
	@include flexbox;
	@include align-items(center);
	@include justify-content(flex-end);
	}
}
.download-button .text{
	// width: 280px;
	// height: 64px;
	margin-right: 40px;
	font-size: 1em;
	@include flexbox;
	display: block;
	text-align: center;
}

.download-button:hover{
	#chrome-icon{
		@include transform(rotate(180deg));
	}
}

header .download-button{
	@include tablet-down{
	display: none;
}
}

#logo{
		width: 45px;
		height: 45px;
}

.loaded .browser{
	@include transform( translateY(-150px));
	opacity: 1;
	@include phone{
		@include transform( translateY(-64px));
	}
	@include tablet{
		@include transform( translateY(-100px));
	}
	@include laptop{
		@include transform( translateY(-150px));
	}
	@include desktop{
		@include transform( translateY(-200px));
	}
	@include large{
		@include transform( translateY(-250px));
	}
}
.browser{
	// margin-top: 100px;
	position: relative;
	background-color: transparent;
	z-index: 1;
	@include center;
	@include transition(all, 0.5s);
	opacity: 0;
	@include transform( translateY(100px));
	-webkit-box-shadow: 10px 10px 20px 10px rgba(0,0,0,0.08);
    -moz-box-shadow: 10px 10px 20px 10px rgba(0,0,0,0.08);
    box-shadow: 10px 10px 20px 10px rgba(0,0,0,0.08);
-moz-border-radius-topleft: 5px;
-webkit-border-top-left-radius: 5px;
 border-top-left-radius: 5px;
-moz-border-radius-topright: 5px;
-webkit-border-top-right-radius: 5px;
border-top-right-radius: 5px;

}

section.landing{
	position: relative;
}



#wave{
	// position:absolute;
	// bottom: 0;
	// left: 0;
	// height: 900px;

	width: 100%;
	height: auto;
	z-index: 10;
	display: block;
	@include transform(translateY(100px));
}

// #wave {
// 	height: 700px;
// }

// #wave path{
// 	// height: 700px;
// }

#wordmark{
	width: 200px;
	margin-left: 16px;
}

.logo-wrapper{
	height: 64px;
	width: 400px;
	@include flexbox;
	@include align-items(center);
	@include tablet-down{
	width: 100%;
	@include justify-content(center)
	}
}

.logo-wrapper svg{
	display: inline;
}

#wave g{
	width: 100%;

}

#wave2{
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: auto;
	-webkit-transform: scale(1);
	transform: scale(1)
}

header{
	height: 64px;
	@include flexbox;
	@include align-items(center);
}

.browser-content{
	height: 400px;
	position: relative;
	@include flexbox;
	@include align-items(center);
	@include justify-content(center);
	// background-color: red;
	-moz-border-radius-topleft: 7px;
-webkit-border-top-left-radius: 7px;
 border-top-left-radius: 7px;
-moz-border-radius-topright: 7px;
-webkit-border-top-right-radius: 7px;
border-top-right-radius: 7px;

background: linear-gradient(90deg, rgba(110,130,216,0.97) 0%, #F1BCBE);
  background-size: 100%;
  animation-name: gradientBackground;

}

.browser-img{
	width: 100%;
}

#circle{
	opacity: 0.4;
}

// .browser .browser-bar{
// 	position: absolute;
// 	top: 0;
// 	left: 0;
// }

@-webkit-keyframes gradientBackground {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
@-moz-keyframes gradientBackground {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
@keyframes gradientBackground { 
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}


#chrome-icon{
	height: 32px;
	width: 32px;
	position: absolute;
	right: 8px;
	top: 8px;
	@include transition(all, 0.3s);
	// @include justify-self(right);
}

.video-row{
	background-color: #8286A5;
	padding: 0 0 64px 0;
	color: white;
	text-align: center;
	h3{
		margin-bottom: 32px;
		@include font-size(1.2em, 1.2em, 1.2em, 1.2em, 1.2em);	
	}
}

.video-wrapper{
		-webkit-box-shadow: 10px 10px 20px 10px rgba(0,0,0,0.08);
    -moz-box-shadow: 10px 10px 20px 10px rgba(0,0,0,0.08);
    box-shadow: 10px 10px 20px 10px rgba(0,0,0,0.08);
-moz-border-radius-topleft: 5px;
-webkit-border-top-left-radius: 5px;
 border-top-left-radius: 5px;
-moz-border-radius-topright: 5px;
-webkit-border-top-right-radius: 5px;
border-top-right-radius: 5px;
}

.text-row, .button-row{
	position: relative;
	z-index: 1;
	padding: 64px 0;
	background-color: #8285A8;
	color: white;
}
.text-row{
	padding: 0 16px 96px 16px ;
	p{
		font-size: 1.2em;
	}
}

.image-row{
	position: relative;
	background-color: #F3F6FA;
}

.button-row{
	text-align: center;
	background-color: #575b80;
	color: white;
	padding: 80px 16px 32px 16px;
	.text-link{
		color: white;
		display: block;
		margin-bottom: 64px;
		@include font-size(1.5em, 1.5em, 1.5em, 1.5em, 1.5em);
	}
 h2{
	font-weight: 600;
	text-align: center;
	@include font-size(2em, 3em, 3em, 3em, 3em);
	margin-bottom: 16px;
	}
	h4{
		@include font-size(1.2em, 1.5em, 1.5em, 1.5em, 1.5em);
		// margin-bottom: 64px;
	}
}

.typed-wrapper{
	display: block;
	height: 64px;
	margin-bottom: 32px;
}

.typed{
	@include font-size(1.8em, 3em, 3em, 3em, 3em);
	margin:0;
	display: inline-block;
	// height: 40px;
	font-weight: 600;
}

.typed-cursor{
	display: inline;
	margin-left: 8px;
	@include font-size(2em, 2em, 2em, 2em, 2em);
}

.button-row .download-button{
	float: none;
	margin-left: auto;
	margin-right: auto;
	max-width: 300px;
	// margin-top: 64px;
	// width: 280px;
	height: 64px;
	margin-bottom: 32px;
	.text{
	// width: 280px;
	// height: 64px;
	margin-right: 40px;

	font-size: 1.3em;
	@include phone{
	font-size: 0.9em;
	}

	}

	#chrome-icon{
	height: 40px;
	width: 40px;
	position: absolute;
	right: 12px;
	top: 12px;
	@include transition(all, 0.3s);
	// @include justify-self(right);
}
}

footer{
	padding: 32px 0;
	margin: 0;
	color: #fff;
	background-color: #575b80;
	text-align: center;
	font-weight: 600;
	position: relative;
	z-index: 1;
	a{
		color: white;
		opacity: 0.8;
		@include phone{
		display: block;
		padding: 8px 0;
	}
	&:hover{
			opacity: 1;
			color: white;
			text-decoration: none;
			cursor: pointer;
		}
	}
}

footer .container{
	// margin: 0;
}